import React from 'react'
import { ExclamationCircleFill, X } from 'react-bootstrap-icons'

const Toast = (props) => {
    return (
        <div className='absolute w-[95%]  z-50 top-2 md:w-96'>
            <div className={`flex animate-fade-in-up items-center justify-between rounded-md ${props.toastType == 'Success' ? 'bg-green-200' : 'bg-red-200'} px-5 py-4 shadow-md duration-600 animate`} >
                <div className="flex items-center gap-4">
                    <div className="text-3xl text-red-600">
                        <ExclamationCircleFill />
                    </div>
                    <div className="text-[14px] font-medium text-red-800">{props.message}</div>
                </div>
                <div className="text-3xl text-slate-800" onClick={() => props.toastHide()}><X /></div>
            </div>
        </div >
    )
}

export default Toast