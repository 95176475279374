import MapReason from "./MapReason";
import { useSearchParams } from "react-router-dom";

function Reason() {
    const [searchParams] = useSearchParams();
    const feederId = searchParams.get('feederid');
    return (
        <>
            <div className='w-full'>
                <MapReason className='' feederId={feederId} />
            </div>

        </>
    );
}

export default Reason;