import React from 'react'
import { useState, useEffect } from 'react';
import { ArrowBarRight, ArrowRightCircleFill } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from "../UserContext";
import { useContext } from "react";
const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {
    const navigate = useNavigate();
    const [interruptionPendingCount, setInterruptionPendingCount] = useState(0);
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        fetchPendingInterruptionTypeCount()
    }, []);

    function fetchPendingInterruptionTypeCount() {
        fetch(`${apiUrl}/utils/getPendingInterruptionTypeCount`, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authorization'),
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setInterruptionPendingCount(data.data.count)
                // setLivePercentage(100 - (((outages.length * 100) / feederState.total_feeder)).toFixed(2))
                // setSsState({
                //     percentage: ((data.data.ss_cnt_under_sfms * 100) / data.data.total_ss_cnt).toFixed(2),
                //     count: `${data.data.ss_cnt_under_sfms} / ${data.data.total_ss_cnt}`
                // })
                // setFeederState({
                //     total_feeder: data.data.total_feeder_cnt,
                //     total_feeder_sfms: data.data.feeder_cnt_under_sfms,
                //     percentage: ((data.data.feeder_cnt_under_sfms * 100) / data.data.total_feeder_cnt).toFixed(2),
                //     count: `${data.data.feeder_cnt_under_sfms} / ${data.data.total_feeder_cnt}`
                // });

            }).catch(error => {
                console.error('Fetch SS and Feeder Count failed:', error);
            });
    }
    const getSurfaceToken = async (req, res) => {
        const response = await fetch(`${apiUrl}/api/surface_token`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authorization'),
                'Content-Type': 'application/json'
            }
        });
        const responseData = await response.json()
        if (responseData.ticket) {
            window.open("https://surface.geourja.com/trusted/" + responseData.ticket + "/views/Dashboard/Home", "_blank");
        }
    }
    return (
        <main className='md:px-12 md:py-5 p-1'>
            <div className="flex items-center mb-3">
                <span className="text-orange-500 text-2xl font-semibold mr-2 " ># </span>
                <span className="text-2xl text-gray-800 font-normal flex items-center">Home</span>
            </div>
            {(user.companyName == 'GUVNL' || user.companyName == 'GETCO') &&
                <div className="flex md:flex-row flex-col gap-1">

                    <Link className='md:w-80 py-1 px-2 flex items-center bg-white shadow rounded-md' to="/outages?outagetypepending=true" target="_blank" rel="noopener noreferrer">
                        {interruptionPendingCount ? (
                            <>
                                <div className="rounded-md min-w-[2.6rem] border-red-800 border-2 flex justify-center text-md bg-red-600 p-2 text-white font-semibold duration-[700ms] transition ease-in-out">
                                    <span>{interruptionPendingCount}</span>
                                </div>
                                <div className="flex flex-col px-1.5 ">
                                    <div className='text-md font-semibold text-gray-600'>Interruption Type</div>
                                    <div className='text-sm font-semibold text-red-600'>Pending</div>
                                </div>
                                {/* <div className="flex flex-grow justify-end px-2">
                                <ArrowRightCircleFill className='text-red-600 text-2xl shadow-md rounded-full' />
                            </div> */}
                            </>)
                            : (
                                <div className='animate-pulse w-full '>
                                    <div className="flex items-center">
                                        <div className="h-10 rounded w-[20%] bg-slate-300"></div>
                                        <div className=" flex flex-col px-1 ">
                                            <div className='text-md font-semibold text-gray-600'>Interruption Type</div>
                                            <div className='text-sm font-semibold text-gray-500'>Pending</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </Link>
                    <Link className='md:w-80 py-1 px-2 flex items-center bg-white shadow text-orange-600 rounded-md hover:bg-orange-500 hover:text-gray-50  cursor-pointer' onClick={getSurfaceToken}>
                        <span className='text-md font-semibold '>Organization Wise Pending Count</span>
                    </Link>
                </div>
            }
        </main>
    )
}

export default Home