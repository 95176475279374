import { useEffect, useState } from "react";
import { XCircle, CaretDownFill, CaretUpFill, ArrowClockwise } from "react-bootstrap-icons";
import Alert from "./Alert";

function SetFaultTypeModal(props) {

    const apiUrl = process.env.REACT_APP_API_URL;
    // const [columns, setColumns] = useState([...props.columns]);
    const [faultDetail, setFaultDetail] = useState({});
    const [selectedReason, setSelectedReason] = useState([])
    const [visibleReasonList, setVisibleReasonList] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState([])
    const [visibleLocationList, setVisibleLocationList] = useState(false)
    const [faultType, setFaultType] = useState(props.faultType)
    const [shutdownTakenBy, setShutdownTakenBy] = useState('');
    const [lcTaken, setLcTaken] = useState('');
    const [lcNo, setLcNo] = useState('');
    const [shutdownDiscomEmpNo, setShutdownDiscomEmpNo] = useState('')
    const [shutdownDiscomEmpName, setShutdownDiscomEmpName] = useState('');
    const [lcDiscomEmpNo, setLcDiscomEmpNo] = useState('')
    const [lcDiscomEmpName, setLcDiscomEmpName] = useState('');
    const [getcoEmpNo, setGetcoEmpNo] = useState('')
    const [getcoEmpName, setGetcoEmpName] = useState('');
    const [otherName, setOtherName] = useState('');
    const [otherMobileNo, setOtherMobileNo] = useState('');
    const [message, setMessage] = useState('');
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [reasonList, setReasonList] = useState([]);
    const [formData, setFormData] = useState({});
    const [feederCategory, setFeederCategory] = useState(null);


    useEffect(() => {
        const fetchFaultDetail = async () => {
            try {
                const response = await fetch(`${apiUrl}/utils/getFaultDetail`, {
                    method: 'POST',
                    body: JSON.stringify({ currentFaultId: props.currentFaultId }),
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('authorization'),
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                else {
                    const responseData = await response.json();
                    setFeederCategory(responseData.detail.categor);
                    setFaultType(responseData.detail.fault_type);

                    setShutdownTakenBy(responseData.detail.shutdown_taken_by);
                    if (responseData.detail.shutdown_taken_by == 'employee') {
                        setShutdownDiscomEmpNo(responseData.detail.shutdown_taken_by_emp_no)

                    }
                    else if (responseData.detail.shutdown_taken_by == 'getco_employee') {
                        setGetcoEmpNo(responseData.detail.shutdown_taken_by_emp_no)

                    }
                    else {
                        setShutdownDiscomEmpNo(responseData.detail.shutdown_taken_by_emp_no)
                        setGetcoEmpNo(responseData.detail.shutdown_taken_by_emp_no)

                    }
                    setLcTaken(responseData.detail.lc_no != null ? "true" : "false")
                    setLcNo(responseData.detail.lc_no)
                    setLcDiscomEmpNo(responseData.detail.lc_taken_by)
                    setOtherMobileNo(responseData.detail.shutdown_taken_by_other_mobile)
                    setOtherName(responseData.detail.shutdown_taken_by_other_name)
                    setSelectedReason(responseData.detail.sfms_reason ? responseData.detail.sfms_reason : [])
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            }

        }
        fetchFaultDetail();

    }, [])

    useEffect(() => {
        if (faultType) {
            getReasonList(faultType)
        }
    }, [faultType]);

    const handleModalOutsideClick = (e) => {
        if (['popupBG-1', 'popupBG-2', 'popupBG-3'].includes(e.target.id)) {
            props.onHide();
        }
    }
    const handleSelectedReason = (e) => {
        const checkboxId = e.target.value;
        if (e.target.checked) {
            // If the checkbox is checked, add the reason to selectedReason
            setSelectedReason([...selectedReason, { id: checkboxId, name: e.target.labels[0].textContent }]);
        } else {
            // If the checkbox is unchecked, remove the reason from selectedReason
            setSelectedReason(prevSelectedReasons =>
                prevSelectedReasons.filter(reason => reason.id !== checkboxId)
            );
        }
    }
    const handleInputChange = async (e) => {

        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            currentFaultId: props.currentFaultId,
            faultType: faultType
        });
    }
    const handleShutdownDiscomEmpNo = (e) => {
        setShutdownDiscomEmpNo(e.target.value);
        handleInputChange(e)
    }
    function handleLcTaken(e) {
        setLcTaken(e.target.value);
        if (e.target.value == "" || e.target.value == "false") {
            console.log(e.target.value)
            setLcDiscomEmpNo('')
            setLcNo('')
        }
        handleInputChange(e)
    }
    const handleLcNo = (e) => {
        setLcNo(e.target.value)
        handleInputChange(e)
    }

    const handleLcDiscomEmpNo = (e) => {
        setLcDiscomEmpNo(e.target.value)
        handleInputChange(e)
    }
    const handleGetcoEmpNo = (e) => {
        setGetcoEmpNo(e.target.value)
        handleInputChange(e)
    }
    const handleOtherName = (e) => {
        setOtherName(e.target.value)
        handleInputChange(e)
    }
    const handleOtherMobileNo = (e) => {
        setOtherMobileNo(e.target.value)
        handleInputChange(e)
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        // setFormData({ ...formData, [name]: value, currentFaultId: props.currentFaultId, faultType: faultType });
    }
    const jsonData = {};
    const handleSaveFaultType = async (e) => {
        e.preventDefault();
        let errors = 0;
        if (faultType == '6') {
            if (lcNo == '' || lcNo == undefined || lcNo == null || isNaN(lcNo)) {
                setMessage('Enter LC No');
                setVisiblePopup(true);
                errors = 1
            }
            else {
                if (lcDiscomEmpNo == '' || lcDiscomEmpNo == undefined || lcDiscomEmpNo == null || isNaN(lcDiscomEmpNo)) {
                    setMessage('Enter Employee No');
                    setVisiblePopup(true)
                    errors = 1
                }
            }
            if (selectedReason.length <= 0) {
                setMessage('Select Reason');
                setVisiblePopup(true)
                errors = 1
            }
        }
        else if (faultType == '5') {

            if (shutdownTakenBy == 'employee') {

                if (shutdownDiscomEmpNo == '' || shutdownDiscomEmpNo == undefined || shutdownDiscomEmpNo == null || isNaN(shutdownDiscomEmpNo)) {
                    setMessage('Enter Discom Employee No. (who take shutdown)');
                    setVisiblePopup(true);
                    errors = 1
                }
                if (lcTaken == "true") {
                    if (lcNo == '' || lcNo == undefined || lcNo == null || isNaN(lcNo)) {
                        setMessage('Enter LC No.');
                        setVisiblePopup(true)
                        errors = 1
                    }
                    if (lcDiscomEmpNo == '' || lcDiscomEmpNo == undefined || lcDiscomEmpNo == null || isNaN(lcDiscomEmpNo)) {
                        setMessage('Enter Employee No (LC)');
                        setVisiblePopup(true)
                        errors = 1
                    }
                }

            }
            else if (shutdownTakenBy == 'getco_employee') {
                if (getcoEmpNo == '' || getcoEmpNo == undefined || getcoEmpNo == null || isNaN(getcoEmpNo)) {
                    setMessage('Enter Getco Employee No. (who take shutdown)');
                    setVisiblePopup(true)
                    errors = 1
                }
            }
            else if (shutdownTakenBy == '') {
                setMessage('Select Shutdown Taken By');
                setVisiblePopup(true)
                errors = 1
            }

        }
        else if (faultType != 7 && faultType != 8 && faultType != 9) {
            setMessage('Select Outage Type');
            setVisiblePopup(true)
            errors = 1
        }

        if (errors == 0) {

            try {
                setSaveLoading(true);
                const response = await fetch(`${apiUrl}/utils/saveFaultType`, {
                    method: 'POST',
                    body: JSON.stringify({
                        faultType: faultType,
                        reasons: (faultType == '5' || faultType == '6' || faultType == '7') ? selectedReason : null,
                        shutdownTakenBy: shutdownTakenBy,
                        shutdownDiscomEmpNo: shutdownDiscomEmpNo,
                        getcoEmpNo: getcoEmpNo,
                        lcTaken: lcTaken,
                        lcNo: lcNo,
                        lcDiscomEmpNo: lcDiscomEmpNo,
                        otherMobileNo: otherMobileNo,
                        otherName: otherName,
                        currentFaultId: props.currentFaultId
                    }),
                    headers: {
                        authorization: 'Bearear ' + localStorage.getItem('authorization'), 'Content-Type': 'application/json'
                    }
                });
                setSaveLoading(false)
                if (response.ok) {
                    props.onHide();
                    props.setGridRefCnt((prv) => ++prv);
                }
                else {
                    const responseData = await response.json()
                    setMessage(responseData.message);
                    setVisiblePopup(!visiblePopup);
                }

            }
            catch (e) {
                console.log(e)
            }

        }
        // await saveFaultType(props.currentFaultId, faultType);

    }
    async function saveFaultType(currentFaultId, faultType) {

    }

    async function handleFaultTypeSelection(e) {


        setFaultType(e.target.value);
        setSelectedReason([])
        setShutdownTakenBy('');
        setLcTaken('');
        setLcNo('');
        setShutdownDiscomEmpNo('')
        setShutdownDiscomEmpName('')
        setLcDiscomEmpNo('')
        setLcDiscomEmpName('')
        setGetcoEmpNo('')
        setGetcoEmpName('')
        setOtherName('')
        setOtherMobileNo('')
        handleInputChange(e);

    }

    function handleShutdownTakenBy(e) {
        console.log(e.target.value)
        setShutdownTakenBy(e.target.value);
        setLcTaken("");
        setLcNo('');
        setShutdownDiscomEmpNo('')
        setShutdownDiscomEmpName('')
        setLcDiscomEmpNo('')
        setLcDiscomEmpName('')
        setGetcoEmpNo('')
        setGetcoEmpName('')
        setOtherName('')
        setOtherMobileNo('')
        handleInputChange(e)

    }


    async function getReasonList(fault_type) {

        try {
            const response = await fetch(`${apiUrl}/utils/getReasonList`, {
                method: 'POST',
                body: JSON.stringify({ faultType: fault_type }),
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('authorization'),
                    'Content-Type': 'application/json'
                }
            });
            const responseData = await response.json()
            setReasonList(responseData.detail)
            console.log(responseData.detail)
        }
        catch (error) {
            console.log(error)
        }


    }
    return (
        <>

            {visiblePopup && (<Alert messageType={"error"} message={message} setVisiblePopup={setVisiblePopup} />)}
            <div className="  fixed inset-0 flex items-start  justify-center z-10 backdrop-blur-sm cursor-pointer" id="popupBG-1" onClick={handleModalOutsideClick}>
                <div className="absolute inset-0 bg-black opacity-30 w-full" id="popupBG-2"></div>
                <div className="animate-fade-in-up bg-white rounded-md m-2 z-10 md:w-1/3 shadow-lg" id="popupBG-3">
                    <div className=" w-full">
                        <div className="flex bg-gray-100 px-5 py-3 flex-row justify-between items-center  border-b border-gray-300 ">
                            <div className="text-md font-semibold text-orange-600 flex items-center"><span>Update Details </span>{saveLoading && <ArrowClockwise className="animate-spin mx-2 text-gray-800" />}</div>
                            <div className="  hover:text-orange-500 my-1 text-xl font-semibold" onClick={props.onHide}><XCircle /></div>
                        </div>
                    </div>
                    <form onSubmit={handleSaveFaultType}>
                        <div className="text-md Capitalize">
                            <div className="px-5 py-4  max-h-screen ">
                                {/* <div className="pt-1 pb-2">
                                    {selectedReason.length > 0 && <>
                                        <div className="flex flex-wrap items-center py-2 px-2 border rounded-md mb-2">
                                            <div className="text-xs">Clear</div>
                                            {selectedReason.map((item) => {
                                                return (<label className="">
                                                    <div className="text-xs  bg-gray-100 border shadow-sm rounded-md font-semibold px-2 mx-1 my-1 py-0.5">{item.name}</div>
                                                </label>)
                                            })
                                            }

                                        </div>
                                    </>}

                                </div> */}
                                {feederCategory ?
                                    <>
                                        <div className="pt-1 pb-2">
                                            <div className="font-semibold text-sm pb-0.5 text-gray-700">Outage Type</div>
                                            <select value={faultType} className='border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500' onChange={(e) => {
                                                handleFaultTypeSelection(e);
                                                handleOnChange(e);
                                            }} name="faultType">
                                                <option value=""> Select</option>
                                                <option value="5">Emergency Shutdown</option>
                                                <option value="6">Planned Shutdown</option>
                                                {feederCategory == 'AGSKY' || feederCategory == 'ADOM' ? <option value="7">AG Phase Changeover</option> : null}
                                                <option value="8">Breaker Testing</option>
                                                <option value="9">No LSTC</option>
                                            </select>
                                        </div> </> : 'Loading...'}

                                {/* <div className="relative  text-left z-30">

                                    <div className="font-semibold text-sm pb-0.5 text-gray-700">Location</div>
                                    <span className="rounded-md shadow-sm" onClick={() => setVisibleLocationList(!visibleLocationList)}>
                                        <div className="inline-flex justify-between w-full items-center rounded-md  border border-gray-300 px-2 py-2 bg-white text-sm font-medium leading-5  text-gray-500 hover:text-gray-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
                                            <span>{selectedReason.length > 0 && <>
                                                <div className="flex flex-wrap items-center  ">
                                                    
                                                    {selectedReason.map((item) => {
                                                        return (<label className="">
                                                            <div className="text-xs  bg-gray-100 border shadow-sm rounded-md font-semibold px-2 mx-0.5 my-0.5 py-0.5">{item.name}</div>
                                                        </label>)
                                                    })
                                                    }

                                                </div>
                                            </>} </span>
                                            <span className="flex">{visibleLocationList ? <CaretUpFill /> : <CaretDownFill />}</span>
                                        </div>
                                    </span>
                                    {visibleLocationList &&
                                        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg">
                                            <div className="rounded-md bg-white shadow-lg border max-h-36 overflow-x-scroll">
                                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">



                                                    {locationList.map((item) => {
                                                        return (<span className="flex items-center space-x-2 px-4 py-1  hover:text-orange-600" key={item.id}>
                                                            <input
                                                                type="checkbox"
                                                                className="form-checkbox h-4 w-4 text-orange-500"
                                                                value={item.id}
                                                                name={item.id}
                                                                id={item.id}
                                                                onChange={handleSelectedLocation}
                                                                label={item.name}
                                                                checked={selectedLocation.some(x => x.id == item.id)}
                                                            />
                                                            <label className=" cursor-pointer" htmlFor={item.id}>{item.name}</label>
                                                        </span>)
                                                    })}



                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div> */}

                                {(faultType == "5" || faultType == "6") && <div className="relative text-left z-20 pt-1">

                                    <div className="font-semibold text-sm pb-0.5 py-2 text-gray-700">Reason</div>
                                    <span className="rounded-md shadow-sm" onClick={() => setVisibleReasonList(!visibleReasonList)}>
                                        <div className="inline-flex justify-between w-full items-center rounded-md  border border-gray-300 px-2 py-3 bg-white text-sm font-medium leading-5  text-gray-500 hover:text-gray-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
                                            <span>{selectedReason.length > 0 && <>
                                                <div className="flex flex-wrap items-center  rounded-md ">
                                                    {/* <div className="text-xs">Clear</div> */}
                                                    {
                                                        selectedReason.map((item) => {
                                                            return (<label className="">
                                                                <div className="text-xs  bg-gray-200  text-gray-800 border shadow-sm rounded-md font-semibold px-2 mx-0.5 my-0.5 py-0.5">{item.name}</div>
                                                            </label>)
                                                        })
                                                    }

                                                </div>
                                            </>} </span>
                                            <span className="flex">{visibleReasonList ? <CaretUpFill /> : <CaretDownFill />}</span>
                                        </div>
                                    </span>
                                    {visibleReasonList &&
                                        <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg ">
                                            <div className="rounded-md bg-white shadow-lg border max-h-36 overflow-x-scroll">
                                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">


                                                    {/* {reason.length > 0 && <div className="text-xs  text-orange-600 mx-1 sticky">Clear</div>} */}
                                                    {reasonList.map((item) => {
                                                        return (<span className="flex items-center space-x-2 px-4 py-1  hover:text-orange-600 w-full hover:bg-gray-100 " key={item.id}>
                                                            <input
                                                                type="checkbox"
                                                                className="form-checkbox h-4 w-4 text-orange-500 hover:cursor-pointer"
                                                                value={item.id}
                                                                name={item.id}
                                                                id={item.id}
                                                                onChange={handleSelectedReason}
                                                                label={item.name}
                                                                checked={selectedReason.some(x => x.id == item.id)}
                                                            />
                                                            <label className=" cursor-pointer" htmlFor={item.id}>{item.name}</label>
                                                        </span>)
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    }


                                </div>}

                                {(faultType == '5') && <div className="">
                                    <div className="py-2">
                                        <div className="font-semibold text-sm pb-0.5 text-gray-700">Shutdown Taken By</div>
                                        <select value={shutdownTakenBy} className='border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500' onChange={(e) => {
                                            handleShutdownTakenBy(e);
                                            handleOnChange(e);
                                        }} name="shutdownTakenBy">
                                            <option value=""> Select</option>
                                            <option value="employee"> Discom Employee</option>
                                            <option value="getco_employee"> Getco Employee</option>
                                            <option value="other_person"> Other Person</option>
                                        </select>
                                    </div>
                                </div>}

                                {shutdownTakenBy == 'employee' &&
                                    <div className="flex gap-2">
                                        <div className="py-2 w-2/3">
                                            <div className="font-semibold text-sm pb-0.5 text-gray-700">Discom Emp No. <span className="text-xs text-orange-500">(Shutdown )</span></div>
                                            <input type="text" className="border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500" name="shutdownDiscomEmpNo" onChange={(e) => { handleShutdownDiscomEmpNo(e); handleOnChange(e) }} value={shutdownDiscomEmpNo} />
                                        </div>
                                        <div className="py-2 w-1/3">
                                            <div className="font-semibold text-sm pb-0.5 text-gray-700">LC Taken</div>
                                            <select className='border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500' onChange={(e) => { handleLcTaken(e); handleOnChange(e); }} name="lcTaken" value={lcTaken}>
                                                <option value=""> Select</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>}
                                {(lcTaken == "true" || faultType == "6") && <div className="flex gap-2">
                                    <div className="w-1/3">
                                        <div className='py-2'>
                                            <div className="font-semibold pb-0.5 text-sm  text-gray-700">LC No.</div>
                                            <input type="text" className="border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500" name="lcNo" onChange={(e) => { handleLcNo(e); handleOnChange(e); }} value={lcNo} />
                                        </div>
                                    </div>
                                    <div className="w-2/3">
                                        <div className='py-2'>
                                            <div className="font-semibold text-sm pb-0.5 text-gray-700">Discom Emp No. <span className="text-xs text-orange-500">(LC )</span></div>
                                            <input type="text" className="border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500" name="lcDiscomEmpNo" onChange={(e) => { handleLcDiscomEmpNo(e); handleOnChange(e); }} value={lcDiscomEmpNo} />
                                            <span className='mt-1 text-sm text-orange-600 ' >{lcDiscomEmpName}</span>
                                        </div>
                                    </div>
                                </div>}
                                {shutdownTakenBy == 'getco_employee' && <div className="py-2">
                                    <div className="font-semibold text-sm pb-0.5 text-gray-700">Getco Emp No.</div>
                                    <input type="text" className="border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500" name="getcoEmpNo" onChange={(e) => { handleGetcoEmpNo(e); handleOnChange(e); }} value={getcoEmpNo} />
                                    <span className='mt-1 text-sm text-orange-600 '>{getcoEmpName}</span>
                                </div>}


                                {shutdownTakenBy == 'other_person' && <div className="flex gap-2">

                                    <div className='py-2 w-2/3'>

                                        <div className="font-semibold text-sm pb-0.5 text-gray-700">Name</div>
                                        <input type="text" className="border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500" name="otherName" onChange={(e) => { handleOtherName(e); handleOnChange(e); }} value={otherName} />

                                    </div>
                                    <div className='py-2 w-1/3'>

                                        <div className="font-semibold text-sm pb-0.5 text-gray-700">Mobile No.</div>
                                        <input type="text" className="border border-gray-400 h-10 rounded-md p-2 text-[14.5px] w-full text-gray-500" name="otherMobileNo" onChange={(e) => { handleOtherMobileNo(e); handleOnChange(e); }} value={otherMobileNo} />

                                    </div>
                                </div>}
                                {/* <div className="border border-b border-gray-400 my-4 border-dashed border-0"></div> */}

                            </div>
                            <div className="w-full bg-gray-100 text-right py-3 px-5 border-t border-gray-300">
                                <button className="w-18 bg-gray-800 hover:bg-black text-white text-sm font-semibold py-1.5 px-4 mr-1 rounded-md" onClick={props.onHide}>
                                    Cancel
                                </button>
                                <button type="submit" className="w-18 bg-orange-600 hover:bg-black text-white text-sm font-semibold py-1.5 px-4 rounded-md">
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>
                </div >
            </div >
        </>
    )
}

export default SetFaultTypeModal;