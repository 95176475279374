import React, { useEffect } from 'react'
import { z } from 'zod';

const PlayGround = () => {
    useEffect(() => {
        console.log(z.date())
    }, [])
    return (
        <h1>Hi</h1>
    )
}

export default PlayGround