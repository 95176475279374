import { PersonCircle, ThreeDotsVertical } from "react-bootstrap-icons";

const LoginNew = () => {
    return (
        <>
            <div className="w-full h-screen flex justify-center items-center bg-[#e1f5fe61] bg-[#e8dfd6]">
                <div className="md:w-1/5 w-full bg-white shadow-lg border rounded-md">
                    <div className="flex justify-center items-center py-4">

                        <p className=" pr-1 pl-2 text-[40px] font-bold bg-clip-text bg-gradient-to-r from-sky-800 via-orange-700 to-orange-500 text-transparent ">
                            OMS
                        </p>
                        <div className="flex flex-col leading-3">
                            <span className=" mt-1 text-gray-800 text-[9px] font-bold">
                                OUTAGE
                            </span>
                            <span className="text-gray-800 text-[9px] font-bold">
                                MANAGEMENT
                            </span>
                            <span className="text-gray-800 text-[9px] font-bold">
                                SYSTEM
                            </span>
                        </div>
                    </div>
                    {/* <div className="px-6 py-4 flex items-center justify-between">
                        <span className="text-[#5C4033] text-4xl font-serif">Login</span>

                    </div> */}
                    <div className="px-6 py-4 flex flex-col gap-5">
                        <div>
                            {/* <lable className="text-[#5C4033]  text-base font-medium">Company</lable> */}
                            <select className="w-full py-2.5 px-2 rounded-md focus:outline-none border border-[#99796a] bg-white" placeHolder="Company" >
                                <option value="" disabled selected>Company</option>
                            </select>
                        </div>
                        <div>
                            {/* <lable className="text-[#5C4033]  text-sm font-medium">Emp No</lable> */}
                            <input type="password" className="w-full py-2.5 px-2 rounded-md  focus:outline-none border border-[#99796a] bg-white" placeHolder="Employee No." />
                        </div>
                        <div>
                            {/* <lable className="text-[#5C4033]  text-sm font-medium">Password</lable> */}
                            <input type="text" className="w-full py-2.5 px-2 rounded-md  focus:outline-none border border-[#99796a] bg-white" placeHolder="Password" />
                        </div>
                    </div>
                    <div className=" bg-orange-600 mx-6 my-5 py-2 px-3 text-[#f5f5f5] rounded-md text-center text-lg font-normal shadow-md hover:bg-gray-500">Login</div>
                    <p className="text-center text-[#8d6856] font-normal"> Develop By GPRD Cell, GUVNL</p>
                    <p className="text-center text-[#8d6856] font-medium text-xs font-inter"> Develop By GPRD Cell, GUVNL</p>
                </div>
                {/* <div className="w-1/4 h-[400px] bg-[#ffffff] shadow-md rounded-md">
                    <div className="flex justify-center items-center">

                        <p className=" pr-1 pl-2 text-[40px] font-bold bg-clip-text bg-gradient-to-r from-sky-800 via-orange-700 to-orange-500 text-transparent ">
                            OMS
                        </p>
                        <div className="flex flex-col leading-3">
                            <span className=" mt-1 text-gray-800 text-[9px] font-bold">
                                OUTAGE
                            </span>
                            <span className="text-gray-800 text-[9px] font-bold">
                                MANAGEMENT
                            </span>
                            <span className="text-gray-800 text-[9px] font-bold">
                                SYSTEM
                            </span>
                        </div>
                    </div>
                    <div className="px-6 py-8 text-center">
                        <span className="text-[#5C4033] text-4xl font-light">Login</span>
                    </div>
                    <div className="px-6 py-4 flex flex-col gap-2">
                        <div>
                            <lable className="text-[#5C4033]  text-base font-medium">Company</lable>
                            <select className="w-full py-1.5 px-2 rounded-md focus:outline-none border border-gray-300 bg-gray-50" >
                                <option></option>
                            </select>
                        </div>
                        <div>
                            <lable className="text-[#5C4033]  text-sm font-medium">Emp No</lable>
                            <input type="password" className="w-full py-1.5 px-2 rounded-md  focus:outline-none border border-gray-300 bg-gray-50" />
                        </div>
                        <div>
                            <lable className="text-[#5C4033]  text-sm font-medium">Password</lable>
                            <input type="text" className="w-full py-1.5 px-2 rounded-md  focus:outline-none border border-gray-300 bg-gray-50" />
                        </div>
                    </div>
                    <div className=" bg-orange-600 mx-5 my-10 py-2.5 px-3 text-[#f5f5f5] rounded-md text-center text-sm font-medium">Login</div>
                </div> */}
            </div>
        </>
    )
}

export default LoginNew;