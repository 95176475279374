import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import UserContext from "../UserContext";
import { useContext } from "react";
import Alert from "./Alert";

const OmsLogin = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);
    const [message, setMessage] = useState('');
    const [visiblePopup, setVisiblePopup] = useState(false);
    const omsToken = searchParams.get("token");
    const omsCompany = searchParams.get("company");
    const omsEmpNo = searchParams.get("emp_no");
    const page = searchParams.get('page');
    console.log(page)
    useEffect(() => {

        if (omsCompany && omsToken && omsEmpNo) {

            async function checkAuthentication() {
                try {
                    const loginResponse = await fetch(`${apiUrl}/login/frm_geourja`,
                        {
                            method: 'POST',
                            body: JSON.stringify({
                                companyName: omsCompany,
                                empNo: omsEmpNo,
                                handshakeToken: omsToken
                            }),
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "authorization": 'Bearer ' + localStorage.getItem('authorization')
                            }
                        }
                    )

                    if (loginResponse.ok) {
                        const response = await loginResponse.json();

                        localStorage.setItem('authorization', response.user.company_name + '.' + response.user.emp_no + '.' + response.user.omsToken);
                        setUser({
                            isLoggedIn: response != null ? true : false,
                            name: response.name,
                            companyName: response.company_name,
                            empNo: response.emp_no,
                            eurja_code: response.organization_code,
                            trimedEurjaCode: response.trimEurjaCode
                        });
                        
                        page != undefined && page != null ? navigate(`/${page}`) : navigate('/');
                       
                        setMessage(response.message);
                        setVisiblePopup(!visiblePopup);
                    }
                    else {
                        const response = await loginResponse.json()
                        setMessage(response.message);
                        setVisiblePopup(!visiblePopup);
                    }
                }
                catch (error) {
                    console.log(error)
                }
            }
            checkAuthentication();
        }
        else {
            navigate('/login')
        }
    }, []);

    return (
        <>
            {visiblePopup && (<Alert messageType={"error"} message={message} setVisiblePopup={setVisiblePopup} />)}
        </>
    );

}

export default OmsLogin;