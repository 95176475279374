import React, { useContext, useState, useEffect } from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom';

import { Navigate, useNavigate } from 'react-router-dom';
import Refresher from './Refresher';
import UserContext from '../UserContext';
import Alert from "./Alert";


const Body = () => {

    const [showTemplate, setShowTemplate] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { user, setUser } = useContext(UserContext);
    const onUserRefresh = () => {
        setShowTemplate(true);
    };
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [visiblePopup, setVisiblePopup] = useState(false);
    useEffect(() => {
        async function getUser() {
            if (localStorage.getItem('authorization')) {
                try {

                    const responsed = await fetch(`${apiUrl}/getUser`,
                        {
                            method: 'GET',
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "authorization": 'Bearer ' + localStorage.getItem('authorization')
                            }
                        });


                    if (responsed.status === 200) {
                        const data = await responsed.json();
                        console.log("data", data)
                        if (data != null) {
                            setUser({
                                isLoggedIn: data != null ? true : false,
                                name: data.name,
                                companyName: data.company_name,
                                empNo: data.emp_no,
                                eurja_code: data.organization_code,
                                trimedEurjaCode: data.trimmed_eurja_code,
                                orgType: data.org_type,
                                permission: data.permission
                            });
                            onUserRefresh();
                        }
                    }
                    else {
                        console.log("else")
                        setUser({});
                        localStorage.clear();
                        navigate('/login');
                    }
                }
                catch (error) {
                    setVisiblePopup(true);
                    setMessage(error.message)
                }
            }
            else {
                setUser({});
                localStorage.clear();
                navigate('/login');
            }
        }
        getUser();
    }, []);
    return (
        <>
            <div className="flex flex-col  bg-gray-50 font-inter">
                <div>
                    {visiblePopup ? (<Alert messageType={"error"} message={message} setVisiblePopup={setVisiblePopup} />) : ''}
                </div>
                {/* <Refresher
                    onRefresh={onUserRefresh}
                    /> */}


                {showTemplate &&
                    <>


                        <Header />
                        <Outlet />


                    </>
                }

            </div>
        </>
    )
}

export default Body;