import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
    return (
        <div className="min-h-screen flex flex-col justify-center items-center w-full bg-white ">
            <div className='flex flex-row items-center'>
                <div className=" pr-1 pl-2 text-[55px] font-bold bg-clip-text bg-gradient-to-r from-slate-800 via-orange-700 to-orange-500 text-transparent ">
                    OMS
                </div>
                <div className="flex flex-col leading-4 pt-1">
                    <span className="text-gray-800 text-[11px] font-bold">
                        OUTAGE
                    </span>
                    <span className="text-gray-800 text-[11px] font-bold">
                        MANAGEMENT
                    </span>
                    <span className="text-gray-800 text-[11px] font-bold">
                        SYSTEM
                    </span>
                </div>

            </div>
            <p className="text-xl font-semibold text-gray-500">404 Error | Page Not Found...</p>
            <Link to="/"><p className="mt-10 text-sm rounded-full shadow-md border font-semibold text-white bg-orange-600 px-4 py-1.5 cursor-pointer hover:bg-orange-700 hover:shadow-lg">Go to Home</p></Link>
        </div >
    )
}

export default ErrorPage