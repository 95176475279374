import React from "react";
import { useContext } from "react";
import UserContext from "../UserContext";
import { ArrowRightCircle, ArrowRightCircleFill, CaretRightFill, FileText } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const Reports = () => {
    const user = useContext(UserContext).user;

    return (
        <>
            <div className=' md:px-12 md:py-8'>
                <div className='max-w-full h-screen min-h-screen mx-auto '>
                    {/* Start Header */}
                    <div className='flex items-center gap-1'>
                        <FileText />
                        <span className='text-xl font-normal  text-gray-800'>Reports</span>
                    </div>
                    {/* End Header */}

                    {/* Start Body */}
                    <div className="flex gap-1">
                        <div className='w-full flex flex-col gap-1 md:gap-2.5 py-6'>
                            <ul className="flex flex-col gap-1">
                                {/* {(user.companyName == 'GUVNL' || user.companyName == 'GETCO') &&
                                    <Link to="/reports/outagetypependingreport">
                                        <li className="bg-gray-50 hover:bg-gray-100 hover:text-gray-800 text-orange-500 shadow-sm cursor-pointer border rounded-md px-3 py-2.5 font-normal flex justify-between items-center">
                                            <span className="">Outage type pending</span>
                                            <ArrowRightCircleFill className="text-2xl " />
                                        </li>
                                    </Link>
                                } */}
                                <span>Available Soon</span>
                            </ul>
                        </div>
                        {/* <div className='w-full flex flex-col gap-1 md:gap-2.5 py-6 px-6'>
                            <ul className="flex flex-col gap-1">
                                <li className="cursor-pointer border rounded-md px-3 py-2.5 hover:bg-gray-100 flex justify-between items-center">
                                    <span>Outage type pending</span>
                                    <ArrowRightCircle className="" />
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div >
        </>
    )
}

export default Reports;