import React from 'react'
import { Building } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

const Modules = () => {
    return (
        <div className='bg-[#F8F8F8] min-h-screen p-2 md:p-5'>
            <div className='flex'>
                <Link to='../substation' target='_blank' className='flex flex-col justify-center items-center gap-2 py-6 px-8 border rounded-md bg-orange-500 text-white font-medium shadow-sm hover:bg-orange-600 hover:cursor-pointer'>
                    <span className='text-xl'><Building /></span>
                    <span>Substation</span>
                </Link>
            </div>
        </div>
    )
}

export default Modules