import React from 'react'
import { ExclamationCircleFill, HandThumbsUpFill, X } from 'react-bootstrap-icons'

const CustomToast = ({ status, messages, hideToast }) => {
    return (
        <div className='absolute w-[95%]  z-50 top-2 md:w-96'>
            <div className={`flex animate-fade-in-up items-center justify-between rounded-md ${status == 'Fail' ? 'bg-red-200' : 'bg-green-100'} px-5 py-4 shadow-md duration-600 animate`} >
                <div className="flex items-center gap-4">
                    <div className={`text-3xl ${status == 'Fail' ? 'text-red-600' : 'text-green-600'}`}>
                        {status == 'Fail' ? <ExclamationCircleFill /> : <HandThumbsUpFill />}
                    </div>
                    <div className="text-[14px] font-medium text-black">
                        {messages.map((message, index) => (
                            <div key={index}> {message}</div>
                        ))}
                    </div>
                </div>
                <div className="text-3xl text-slate-800" onClick={() => hideToast()}><X /></div>
            </div>
        </div >
    )
}

export default CustomToast