import React, { useState, useEffect } from "react";
import { XCircle } from "react-bootstrap-icons";
import moment from "moment";
import Toast from "./Toast";
import CustomToast from "./CustomToast";

const AddManualEndTimeModal = (props) => {

    const [data, setData] = useState(null);
    const [id, setId] = useState(props.currentFaultId)
    const [outageEndDate, setOutageEndDate] = useState();
    const [outageEndTime, setOutageEndTime] = useState();
    const [showToast, setShowToast] = useState(false);
    const [status, setStatus] = useState();
    const [messages, setMessages] = useState([])
    const hideToast = () => {
        setShowToast(false)
    }
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/outage/${id}`, {
                    method: 'GET',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": "Bearer " + localStorage.getItem("authorization")
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.data);
            }
            catch (error) {
                console.log(error.message)
            }
        }
        fetchData();
    }, []);

    const handleSubmit = async (e) => {

        e.preventDefault();

        let errorMessages = [];
        let startDateTime;

        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss')

        if (!data.fault_start_datetime) {
            errorMessages.push("Start DateTime is not valid. Please Refresh the page.");
            setShowToast({
                status: 'Fail',
                messages: errorMessages,
                hideToast: hideToast
            });
            return
        }
        else {
            startDateTime = moment(data.fault_start_datetime, 'DD-MM-YYYY HH:mm:ss');
        }

        if (!(outageEndDate && outageEndTime)) {
            errorMessages.push("Enter valid End Date/Time.");
            setShowToast({
                status: 'Fail',
                messages: errorMessages,
                hideToast: hideToast
            });
            return
        }
        else {

            const endDateTime = moment(`${outageEndDate} ${outageEndTime}`, 'YYYY-MM-DD HH:mm:ss');

            if (endDateTime.isAfter(currentDateTime)) {
                errorMessages.push("Future DateTime is not allow.");
            }
            else if (startDateTime.isAfter(endDateTime)) {
                errorMessages.push("End Date/Time should not be before Start Date/Time.");
            }
        }
        if (errorMessages.length > 0) {
            setShowToast({
                status: 'Fail',
                messages: errorMessages,
                hideToast: hideToast
            });

        } else {
            try {
                const response = await fetch(`${apiUrl}/outage/addManualEndDateTime`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: id,
                        outageEndDate: outageEndDate,
                        outageEndTime: outageEndTime
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": "Bearer " + localStorage.getItem("authorization")
                    }
                });
                if (!response.ok) {
                    const responseData = await response.json()
                    setShowToast({
                        status: 'Fail',
                        messages: [responseData.message],
                        hideToast: hideToast
                    });
                    return;
                }
                else {
                    const responseData = await response.json()
                    setShowToast({
                        status: 'Success',
                        messages: [responseData.message],
                        hideToast: hideToast
                    });
                    props.onHide();
                    setTimeout(() => {
                        setShowToast(false);
                        props.setGridRefCnt((prv) => ++prv);
                    }, 2000);
                }
            }
            catch (error) {
                setShowToast({
                    status: 'Fail',
                    messages: [error.message],
                    hideToast: hideToast
                });
                return;
            }

        }
    }


    return (

        < div className="  fixed inset-0 flex items-start  justify-center z-10 backdrop-blur-sm cursor-pointer" id="popupBG-1" >
            <div className="absolute inset-0 bg-black opacity-30 w-full" id="popupBG-2"></div>
            <div className="animate-fade-in-up bg-white rounded-md m-2 z-10 md:w-1/4 shadow-lg" id="popupBG-3">
                {showToast && <CustomToast
                    status={showToast.status}
                    messages={showToast.messages}
                    hideToast={hideToast} />}
                <div className=" w-full">
                    <div className="flex bg-gray-100 px-5 py-3 flex-row justify-between items-center  border-b border-gray-300 ">
                        <div className="text-md font-semibold text-orange-600 flex items-center"><span>Add Outage End Date/Time </span></div>
                        <div className="  hover:text-orange-500 my-1 text-xl font-semibold" ><XCircle onClick={props.onHide} /></div>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>

                    <div className="text-md Capitalize">
                        <div className="px-5 py-4  max-h-screen ">
                            <div className="flex bg-gray-100 gap-x-2 mb-4">
                                <div className="px-0.5 bg-green-500 "></div>
                                <div className="py-1">
                                    <div className="flex text-xs"><span className="text-xs font-medium">Feeder Name : </span><span className="text-orange-500 font-medium pl-1">{data?.feeder_name}</span></div>
                                    <div className="flex text-xs"><span className="text-xs font-medium">Outage DateTime : </span><span className="text-orange-500 font-medium pl-1">{data?.fault_start_datetime}</span></div>
                                    <div className="flex text-xs"><span className="text-xs font-medium">Interruption Type : </span><span className="text-orange-500 font-medium pl-1">Manual</span></div>
                                </div>
                            </div>
                            <div className="flex gap-2 w-full">
                                <div className="flex flex-col w-full">
                                    <span className="text-sm font-medium">Outage End Date</span>
                                    <div className="">
                                        <input type="date" className="text-sm font-medium border w-full px-2 py-1.5 rounded-md" value={outageEndDate} onChange={(e) => { setOutageEndDate(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <span className="text-sm font-medium">Outage End Time</span>
                                    <div className="">
                                        <input type="time" className="text-sm font-medium border w-full px-2 py-1.5 rounded-md" value={outageEndTime} onChange={(e) => { setOutageEndTime(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full bg-gray-100 text-right py-3 px-5 border-t border-gray-300">
                            <button className="w-18 bg-gray-800 hover:bg-black text-white text-sm font-semibold py-1.5 px-4 mr-1 rounded-md" onClick={props.onHide}>
                                Cancel
                            </button>
                            <button type="submit" className="w-18 bg-orange-600 hover:bg-black text-white text-sm font-semibold py-1.5 px-4 rounded-md">
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    );
}

export default AddManualEndTimeModal;