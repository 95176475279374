import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { Infinity, XCircle, ArrowClockwise } from "react-bootstrap-icons";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

const apiUrl = process.env.REACT_APP_API_URL;

function MapReason(props) {
    const [visibleModal, setVisibleModal] = useState(false);
    const [lineData, setLineData] = useState();
    const [locationData, setLocationData] = useState()
    const [defaultCenter, setDefaultCenter] = useState([22, 72]);
    const feederId = props.feederId
    const [zoom, setZoom] = useState(18);


    useEffect(() => {

        const getFeederGeoJson = async () => {
            try {
                const response = await fetch(`${apiUrl}/utils/getFeederGeoJson`, {
                    method: 'POST',
                    body: JSON.stringify({
                        feederId: feederId
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "authorization": 'Bearer ' + localStorage.getItem('authorization')
                    }
                });
                const data = await response.json();
                setLineData(data.lineDetail[0]['row_to_json']);
                setLocationData(data.locationDetail[0]['row_to_json']);
                // Find the marker with 'Gentry' as hlt and set it as the default center
                const gentryMarker = data.locationDetail[0]['row_to_json'].features.find(feature => feature.properties.hlt === 'Gentry');
                console.log("gentry", gentryMarker)
                if (gentryMarker) {
                    setDefaultCenter([gentryMarker.geometry.coordinates[1], gentryMarker.geometry.coordinates[0]]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle the error, e.g., display an error message or retry the request
            }
        }
        getFeederGeoJson();
    }, []);

    const getMarkerIcon = (feature) => {
        // Determine which custom icon to use based on the marker type
        const markerType = feature.properties.hlt;

        // Define custom icons for different marker types
        const icons = {
            'Gentry': L.icon({
                iconUrl: '../img/icon/gentry.png',
                iconSize: [19, 19],
            }),
            'HT Pole': L.icon({
                iconUrl: '../img/icon/pole.png',
                iconSize: [10, 10],
            }),
            'Transformer': L.icon({
                iconUrl: '../img/icon/tc.png',
                iconSize: [12, 12],
            }),
            'Switch': L.icon({
                iconUrl: '../img/icon/switch.png',
                iconSize: [12, 12],
            }),
            'CTPT': L.icon({
                iconUrl: '../img/icon/ctpt.png',
                iconSize: [13, 13],
            }),
            // Add more custom icons as needed
        };

        // Return the appropriate icon based on the marker type
        return icons[markerType] || L.icon({
            iconUrl: 'path/to/default-icon.png', // A default icon in case of unknown marker types
            iconSize: [32, 32],
        });
    };
    return (
        <>
            {/* {visibleModal &&

                <div className="  fixed inset-0 flex items-start  justify-center z-49 backdrop-blur-sm cursor-pointer z-[998]" id="popupBG-1" >
                    <div className="absolute inset-0 bg-black opacity-30 w-full" id="popupBG-2"></div>
                    <div className="animate-fade-in-up bg-white rounded-md m-2 z-10 md:w-1/3 shadow-lg" id="popupBG-3">
                        <div className=" w-full">
                            <div className="flex bg-gray-100 px-5 py-3 flex-row justify-between items-center  border-b border-gray-300 ">

                                <div className="text-md font-semibold text-orange-600 flex items-center"><span>Update Details</span></div>

                                <div className="  hover:text-orange-500 my-1 text-xl font-semibold" onClick={() => setVisibleModal(!visibleModal)}><XCircle /></div>
                            </div>
                        </div>
                    </div>
                </div >
            } */}

            {
                locationData ? (
                    <>

                        <div className="animate-fade-in-up bg-white rounded-lg  z-[999] md:w-1/4  shadow-lg w-1/3 fixed left-0">
                            <div className=" w-full ">
                                <div className="flex pt-3 px-3 pb-2 flex-row justify-between items-center  border-b">
                                    <div className="text-md font-semibold text-orange-600 ">Reason Detail</div>
                                    <div className="  hover:text-orange-500 my-1 text-xl font-semibold" ><XCircle /></div>
                                </div>
                            </div>


                            <div className="text-md Capitalize ">
                                <div className="px-5 py-1  h-80 overflow-auto">
                                    {
                                        // columns.map((data, idx) => {

                                        //     return (

                                        //         <div className="mt-1">
                                        //             <input
                                        //                 type="Checkbox"
                                        //                 key={`cb-${data.id}`}
                                        //                 id={`cb-${data.id}`}
                                        //                 checked={data.display}
                                        //                 onChange={(e) => handleCheckboxChange(idx)}
                                        //             />
                                        //             <lable className="m-2">{data.headerName}</lable>
                                        //         </div>)
                                        // })
                                    }
                                </div>
                                <div className="w-full text-right py-3 px-2 border-t">
                                    <button className="w-18  bg-orange-600 hover:bg-black text-white font-semibold py-1 px-3 rounded-md" >
                                        Save
                                    </button>
                                </div>
                            </div>


                        </div >

                        <MapContainer center={defaultCenter} zoom={zoom} className='w-full h-screen' zoomControl={false} >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={22} />
                            <GeoJSON
                                data={lineData}
                                pointToLayer={(feature, latlng) => {
                                    return L.circleMarker(latlng);
                                }}
                                onEachFeature={(feature, layer) => {
                                    layer.on('click', () => {

                                        alert(`Clicked on ${feature.properties.id}`);
                                    });
                                }}
                            />

                            <GeoJSON
                                data={locationData}
                                pointToLayer={(feature, latlng) => {
                                    return L.marker(latlng, {
                                        icon: getMarkerIcon(feature, latlng), // Set the custom icon
                                    })
                                }}
                                onEachFeature={(feature, layer) => {
                                    layer.on('click', () => {
                                        // Handle the click event for the CircleMarker here
                                        alert(`Clicked on ${feature.properties.id}`);
                                    });
                                }}
                            />

                        </MapContainer>
                    </>
                ) : (
                    <span>Loading...</span>
                )
            }
        </>
    );
}

export default MapReason;
