import React, { useEffect, useState } from 'react';

const RedGreenEffect = ({ currentValue }) => {
    const [previousValue, setPreviousValue] = useState(currentValue);
    const [colorClass, setColorClass] = useState('text-black');

    useEffect(() => {
        if (currentValue > previousValue) {
            setColorClass('bg-red-500 text-white');
        } else if (currentValue < previousValue) {
            setColorClass('bg-green-500 text-white');
        } else {
            setColorClass('text-black');
        }

        // Update the previous value after setting the color
        const timer = setTimeout(() => {
            setPreviousValue(currentValue);
        }, 1000); // Adjust this delay as needed

        return () => clearTimeout(timer);
    }, [currentValue, previousValue]);

    return (
        <span className={`${colorClass} transition-colors duration-200 font-semibold px-1  rounded-sm md:rounded-md`}>
            {currentValue}
        </span>
    );
};

export default RedGreenEffect;