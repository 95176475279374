import "./style.css";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from "./components/Login";
import OmsLogin from "./components/OmsLogin";
import Body from "./components/Body";
import Home from "./components/Home";
import Reports from "./components/Reports";
import ErrorPage from "./components/ErrorPage";
import Outages from "./components/Outages";
import { useContext } from "react";
import UserContext from "./UserContext";
import React, { useState, useEffect } from "react";
import Refresher from "./components/Refresher";
import Reason from "./components/Reason";
import Summary from "./components/Summary";
import LoginNew from "./components/LoginNew";
import AddOutage from "./components/AddOutage";
import ViewOutage from "./components/ViewOutage";
import OutageTypePendingReport from "./components/OutageTypePendingReport";
import ConditionalRoute from "./components/ConditionalRoute";
import PlayGround from "./components/PlayGround";
import AddSubstation from "./components/AddSubstation";
import Substation from "./components/Substation";
import ViewSubstation from "./components/ViewSubstation";
import Modules from "./components/Modules";
import EditSubstation from "./components/EditSubstation";

// import SummaryTest from "./components/SummaryTest";

// import { Provider } from "react-redux";
// import store from "./utils/store";

// const user = useContext(userContext);



const router = createBrowserRouter([
    {
        path: '/',
        element: <Body />,
        errorElement: <ErrorPage />,
        children: [{
            path: '',
            element: <Home />
        }, {
            path: 'reports',
            element: <Reports />
        },
        // {
        //     path: '/reports/outagetypependingreport',
        //     element: <OutageTypePendingReport />
        // },
        {
            path: 'outages',
            element: <Outages />,
            children: [{
                path: '',
                element: <ViewOutage />
            },
            {
                path: 'add',
                element: <ConditionalRoute path="/outages/add"><AddOutage /></ConditionalRoute>
            }]
        }, {
            path: 'summary',
            element: <Summary />
        }, {
            path: 'modules',
            element: <ConditionalRoute path="/modules"><Modules /></ConditionalRoute>
        }, {
            path: 'substation',
            element: <ConditionalRoute path="/modules"><Substation /></ConditionalRoute>,
            children: [{
                path: '',
                element: <ViewSubstation />
            },
            {
                path: 'create',
                element: <ConditionalRoute path="/substation/create"><AddSubstation /></ConditionalRoute>
                // element: <AddSubstation />
            },
            {
                path: ':id',
                element: <ConditionalRoute path="/substation/:id"><EditSubstation /></ConditionalRoute>
                // element: <AddSubstation />
            }
            ]
        }]
    }, {
        path: '/login',
        element: <Login />
    },
    {
        path: '/loginnew',
        element: <LoginNew />
    },
    , {
        path: '/omslogin',
        element: <OmsLogin />
    }, {
        path: '/reason',
        element: <Reason />
    },
    {
        path: '/playground',
        element: <PlayGround />
    }
]);

function App() {

    const [userData, setUserData] = useState({});

    const { user, setUser } = useContext(UserContext);
    // const navigate = useNavigate();


    return (
        <UserContext.Provider value={{ user: userData, setUser: setUserData }}>
            <RouterProvider router={router} />
        </UserContext.Provider >

    );
}

export default App;
