// import React, { useState, useEffect } from 'react';
// import moment from 'moment';

// const LiveDuration = ({ currentLocalDateTime, timestamp }) => {
//     const [timeAgo, setTimeAgo] = useState('');
//     const [currentDatetime, setCurrentDatetime] = useState(moment(currentLocalDateTime).format('YYYY-MM-DD HH:mm:ss'));
//     const [commentDatetime] = useState(moment(timestamp).format('YYYY-MM-DD HH:mm:ss'))

//     // This effect is responsible for updating the currentDatetime every minute
//     // useEffect(() => {
//     //     const intervalId = setInterval(() => {
//     //         setCurrentDatetime(prev => moment(prev).add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
//     //     }, 60000);

//     //     return () => clearInterval(intervalId);
//     // }, []);

//     // This effect is responsible for updating the timeAgo based on the currentDatetime
//     useEffect(() => {
//         // Calculate timeDifference when currentDatetime changes
//         const timeDifference = moment.duration(moment(currentDatetime).diff(moment(commentDatetime)));

//         // Update the timeAgo based on timeDifference
//         if (timeDifference.asSeconds() < 60) {
//             setTimeAgo('Just now');
//         } else if (timeDifference.asMinutes() < 60) {
//             setTimeAgo(`${Math.floor(timeDifference.asMinutes())} mins ago`);
//         } else if (timeDifference.asHours() < 24) {
//             const hours = Math.floor(timeDifference.asHours());
//             const minutes = Math.floor(timeDifference.minutes());
//             setTimeAgo(`${hours} hr${hours !== 1 ? 's' : ''} & ${minutes} min${minutes !== 1 ? 's' : ''} ago`);
//         } else {
//             const days = Math.floor(timeDifference.asDays());
//             const remainingHours = Math.floor(timeDifference.asHours() % 24);
//             const remainingMinutes = Math.floor(timeDifference.minutes());
//             setTimeAgo(`${days} day${days !== 1 ? 's' : ''}${remainingHours ? `, ${remainingHours} hr${remainingHours !== 1 ? 's' : ''}` : ''}${remainingMinutes ? ` & ${remainingMinutes} min${remainingMinutes !== 1 ? 's' : ''}` : ''} ago`);
//         }
//     }, [timeAgo]); // This useEffect depends on currentDatetime

//     return <div className='text-xs text-gray-400 font-medium text-ellipsis'><p>{timeAgo}</p></div>;
// };

// export default LiveDuration;
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// const LiveDuration = ({ currentLocalDateTime, timestamp }) => {
//     const [timeAgo, setTimeAgo] = useState('');

//     useEffect(() => {
//         const calculateTimeAgo = () => {
//             const commentMoment = moment(timestamp);
//             const duration = moment.duration(commentMoment.diff(moment(currentLocalDateTime)));
//             setTimeAgo(duration.humanize(true));
//         };

//         calculateTimeAgo();

//         // Update timeAgo every minute
//         const intervalId = setInterval(calculateTimeAgo, 60000);

//         return () => clearInterval(intervalId);
//     }, [currentLocalDateTime, timestamp]);

//     return <div className='text-xs text-gray-400 text-ellipsis'><p>{timeAgo}</p></div>;
// };
const LiveDuration = ({ currentLocalDateTime, timestamp }) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const calculateTimeAgo = () => {
            const commentMoment = moment(timestamp);
            const currentMoment = moment(currentLocalDateTime);
            const duration = moment.duration(currentMoment.diff(commentMoment));

            // Extract days, hours, and minutes
            const days = Math.floor(duration.asDays());
            const hours = Math.floor(duration.asHours()) % 24;
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            // Format the output conditionally
            let timeAgo = '';
            if (days > 0) {
                timeAgo += `${days} D${days !== 1 ? 's' : ''} `;
            }
            if (hours > 0) {
                timeAgo += `${hours} Hr${hours !== 1 ? 's' : ''} `;
            }
            if (minutes > 0) {
                timeAgo += `${minutes} Min${minutes !== 1 ? 's' : ''} `;
            }
            if (timeAgo === '' && seconds < 60) {
                timeAgo = 'just now';
            } else if (timeAgo === '') {
                timeAgo = `${seconds} sec${seconds !== 1 ? 's' : ''} ago`;
            } else {
                timeAgo += 'ago';
            }

            setTimeAgo(timeAgo.trim());
        };

        calculateTimeAgo();

        // Update timeAgo every minute
        const intervalId = setInterval(calculateTimeAgo, 60000);

        return () => clearInterval(intervalId);
    }, [currentLocalDateTime, timestamp]);

    return <div className='text-xs text-gray-400 text-ellipsis'><p>{timeAgo}</p></div>;
};

export default LiveDuration;
