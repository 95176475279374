import { useState, useEffect, useContext } from 'react'
import UserContext from "../UserContext";
import axios from 'axios';
import moment from 'moment';
import { BoxArrowUpRight, Download, Filter, Funnel, FunnelFill, GearFill, Geo, GeoAlt, Map, MapFill, Person, PersonBadgeFill, PersonFill, X, XLg } from 'react-bootstrap-icons';
import Loader from './Loader';
import LiveDuration from './LiveDuration'
import RedGreenEffect from './RedGreenEffect';
import { Link } from 'react-router-dom';
const socketURL = process.env.REACT_APP_SOCKET_URL;
const apiUrl = process.env.REACT_APP_API_URL;


const Summary = () => {

    const { user } = useContext(UserContext);
    const [userCount, setUserCount] = useState(0);

    const [currentDatetime, setCurrentDatetime] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [liveOutages, setLiveOutages] = useState([]);
    const [filteredLiveOutages, setFilteredLiveOutages] = useState([]);
    const [durationFilter, setDurationFilter] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [filterCategory, setFilterCategory] = useState([
        { name: 'ADOM', isVisible: false },
        { name: 'GIDC', isVisible: true },
        { name: 'HTEX', isVisible: true },
        { name: 'IND', isVisible: true },
        { name: 'JGY', isVisible: true },
        { name: 'LL', isVisible: true },
        { name: 'SOLAR', isVisible: true },
        { name: 'URBAN', isVisible: true }
    ])
    const [categoryCount, setCategoryCount] = useState();
    // const initialCategories = [
    //     { name: 'ADOM', isVisible: false },
    //     { name: 'GIDC', isVisible: true },
    //     { name: 'HTEX', isVisible: true },
    //     { name: 'IND', isVisible: true },
    //     { name: 'JGY', isVisible: true },
    //     { name: 'LL', isVisible: true },
    //     { name: 'SOLAR', isVisible: true },
    //     { name: 'URBAN', isVisible: true },
    // ];
    const [filterParams, setFilterParams] = useState({
        categoryFilter: [
            { name: 'ADOM', isVisible: false },
            { name: 'GIDC', isVisible: true },
            { name: 'HTEX', isVisible: true },
            { name: 'IND', isVisible: true },
            { name: 'JGY', isVisible: true },
            { name: 'LL', isVisible: true },
            { name: 'SOLAR', isVisible: true },
            { name: 'URBAN', isVisible: true }
        ],
        durationFilter: durationFilter
    });



    const handleDurationChange = (e) => {
        setDurationFilter(e.target.value);
    };

    let ws = null
    async function getLiveOutages() {
        setIsLoading(true);
        const authToken = localStorage.getItem('authorization');

        try {
            const response = await axios.get(`${apiUrl}/summary/getLiveOutages?allowAdom=true`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            });
            const initialOutagesList = response.data.data.map((e) => {
                return {
                    sfmsId: e.sfms_id,
                    feederName: e.feeder_name,
                    feederId: e.feeder_id,
                    category: e.categor,
                    subdivision: e.subdivision_name,
                    substation: e.ss_name,
                    population: e.population,
                    eventDateTime: e.fault_start_date + " " + e.fault_start_time,
                    oTime: moment(e.fault_start_date + " " + e.fault_start_time, "DD-MM-YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss'),
                    villageCnt: e.vill_count,
                    currentDatetime: response.data.currentDatetime,
                    interruptionType: e.interruption_type
                }
            });

            setCurrentDatetime(moment(response.data.currentDatetime).format('YYYY-MM-DD HH:mm:ss'));

            setLiveOutages(initialOutagesList)
        }
        catch (error) {
            console.error('Error fetching live outages:', error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        // Fetch Initial Data 
        getLiveOutages();

        ws = new WebSocket(socketURL);
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log(message)
            if (message.type === 'userCount') {
                setUserCount(message.count);
            }
            updateLiveOutages(message);

        };
        const intervalId = setInterval(() => {
            setCurrentDatetime(prev => moment(prev).add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'));
        }, 60000);

        return () => {
            clearInterval(intervalId);
            if (!ws) {
                ws.close(); // Cleanup on unmount
            }
        }
    }, []);

    useEffect(() => {
        const filteredOutages = liveOutages.filter((outage) => {
            const eventTime = moment(outage.eventDateTime, 'DD-MM-YYYY HH:mm:ss', true);
            const categoryVisibility = filterParams.categoryFilter.find(cat => cat.name === (outage.category == 'AGSKY' ? 'ADOM' : outage.category))?.isVisible;;
            return moment().diff(eventTime, 'minutes') > filterParams.durationFilter && categoryVisibility
        });

        setFilteredLiveOutages(filteredOutages);

    }, [liveOutages, filterParams])

    useEffect(() => {
        // setFilteredLiveOutages(liveOutages);
        const categoryCounts = liveOutages.reduce((acc, outage) => {
            const category = outage.category == 'AGSKY' ? 'ADOM' : outage.category;
            const eventTime = moment(outage.eventDateTime, 'DD-MM-YYYY HH:mm:ss', true); // Specifying the format and strict parsing
            const categoryVisibility = filterParams.categoryFilter.find(cat => cat.name === (outage.category == 'AGSKY' ? 'ADOM' : outage.category))?.isVisible;;

            if (moment().diff(eventTime, 'minutes') > durationFilter && categoryVisibility) {
                if (acc[category]) {
                    acc[category] += 1;
                } else {
                    acc[category] = 1;
                }
            }
            return acc;
        }, {});
        setCategoryCount(categoryCounts);
    }, [filteredLiveOutages]);

    function filterApply() {
        setFilterParams({
            categoryFilter: filterCategory,
            durationFilter: durationFilter
        });
        setShowFilter(false)
    }
    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFilterCategory(current =>
            current.map(cat => cat.name === value ? { ...cat, isVisible: checked } : cat)
        );
        console.log(filterParams)
    };
    function updateLiveOutages(message) {
        console.log(message)
        if (user.trimedEurjaCode == '96' || (user.companyName == 'GETCO' && String(message.ssEurjaCode).indexOf(user.trimedEurjaCode) > -1) || (user.companyName != 'GETCO' && String(message.sdnEurjaCode).indexOf(user.trimedEurjaCode) > -1)) {
            setLiveOutages(current => {
                const index = current.findIndex(e => e.sfmsId === message.sfmsId);
                if (message.eventType == 1 && index === -1) {
                    return [...current, message].sort((a, b) => moment(b.eventDateTime, 'DD-MM-YYYY HH:mm:ss').unix() - moment(a.eventDateTime, 'DD-MM-YYYY HH:mm:ss').unix());
                } else if (message.eventType == 0 && index !== -1) {
                    let updated = [...current];
                    updated.splice(index, 1);
                    return updated
                }
                return current.sort((a, b) => moment(b.eventDateTime, 'DD-MM-YYYY HH:mm:ss') - moment(a.eventDateTime, 'DD-MM-YYYY HH:mm:ss'))
            });
        }
    }


    const getSurfaceToken = async (req, res) => {
        const response = await fetch(`${apiUrl}/api/surface_token`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authorization'),
                'Content-Type': 'application/json'
            }
        });
        const responseData = await response.json()
        if (responseData.ticket) {
            window.open("https://surface.geourja.com/trusted/" + responseData.ticket + "/views/Power_Off/Dashboard1?:iid=8", "_blank");
        }
    }

    const downloadCsv = () => {
        const jsonData = liveOutages.map((e) => {
            return {
                "Sub Division": e.subdivision,
                "Substation": e.substation,
                "Feeder Id": e.feederId,
                "Feeder Name": e.feederName,
                "Category": e.category,
                "Outage Start DateTime": e.oTime,
                "Interruption Type": e.interruptionType == "3" ? "Protection" : "Manual"

            }
        })
        // Convert JSON to CSV
        const csvRows = [];

        // Get the headers
        const headers = Object.keys(jsonData[0]);
        csvRows.push(headers.join(','));

        // Loop over the rows
        for (const row of jsonData) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '""');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        // Create CSV string
        const csvString = csvRows.join('\n');

        // Create a Blob from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Create a download link and trigger download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'data.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    return (
        <>
            <div className='w-full h-screen bg-gray-100 px-3 py-1 md:px-12 md:py-5'>
                <div className='flex justify-between items-center pb-1 '>
                    <div className='flex gap-2 place-items-center pb-2'>
                        <div className='text-md font-semibold text-gray-700 md:text-xl'>Summary </div>
                        <div className='flex items-center'>
                            <div className='bg-red-500 py-0.5 px-2 rounded-md text-xs flex justify-center items-center gap-2'>
                                <PersonFill className='text-gray-50' />
                                <span className='text-gray-50 font-semibold'>{userCount}</span>
                            </div>
                        </div>
                        <div className="text-xs rounded-md bg-gray-50 round-sm py-1.5 px-2 font-semibold cursor-pointer shadow text-orange-600 hover:bg-gray-100" onClick={getSurfaceToken}>Organization View</div>

                    </div>

                    <div className='flex gap-1'>
                        {filterParams.durationFilter > 0 && (<div className="flex items-center gap-1 text-xs rounded-full  py-1.5 px-2.5 font-semibold cursor-pointer text-orange-500 border bg-gray-50 shadow-sm " >
                            <span>{filterParams.durationFilter / 60} Hours</span>
                            <X className=' text-lg text-gray-400 hover:text-gray-800' onClick={() => {

                                setDurationFilter(0)
                                setFilterParams({
                                    categoryFilter: filterCategory,
                                    durationFilter: 0
                                });

                            }
                            } />
                        </div>)}
                        < div className="text-xs rounded-[4px] py-2 px-2.5 font-semibold cursor-pointer shadow text-white bg-orange-600 hover:bg-orange-500" onClick={() => { setShowFilter(prev => !prev) }}><FunnelFill /></div>
                    </div>

                </div>

                <div className='flex flex-col gap-3 md:gap-x-3 md:flex-row'>
                    {isLoading && <Loader />}
                    <div className='w-full md:w-1/4'>
                        <div className='w-full md:h-[570px] bg-white border border-gray-100  shadow-lg'>
                            <div className='border-b px-2 py-1.5 bg-gray-700 flex justify-between items-center md:px-4 md:py-3'>
                                <div className='text-sm text-gray-50 font-semibold md:text-md'>Live Outage Counts by Category</div>

                            </div>
                            <div className=' p-2 grid grid-cols-4 gap-1 overflow-x-scroll mobile-scroll-container md:grid-cols-2 '>
                                <div className='rounded-md  bg-gray-50 px-4 py-4 border shadow-sm' key="total">
                                    <div className='text-[12px] font-bold text-center text-orange-600 md:text-[14px]'>
                                        TOTAL
                                    </div>
                                    <div className='text-[12px] text-center md:text-[16px] pt-1'>
                                        <RedGreenEffect currentValue={filteredLiveOutages.length} />
                                    </div>
                                </div>
                                {categoryCount && Object.entries(categoryCount)
                                    .sort((a, b) => b[1] - a[1]) // Sorting entries by count in descending order.
                                    .map(([category, total_count]) => (
                                        <div className='rounded-md bg-gray-50 px-4 py-4 border shadow-sm' key={category}>
                                            <div className='text-[12px] font-bold text-center text-orange-600 md:text-[14px] '>
                                                {category}
                                            </div>
                                            <div className='text-[12px] text-center md:text-[15px] pt-1'>
                                                <RedGreenEffect currentValue={total_count} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                    <div className='w-full bg-white border border-gray-100  shadow-lg  md:w-1/2'>
                        <div className='border-b px-2 py-1.5 bg-gray-700 flex justify-between items-center md:px-4 md:py-3'>
                            <div className='text-sm text-gray-50 font-semibold md:text-md'>Live Outage Feeders</div>
                            <div className='text-sm text-gray-50 font-semibold md:text-md' onClick={downloadCsv} ><Download /></div>
                        </div>

                        <div className="h-[520px] overflow-scroll ">
                            <ul className="divide-y divide-gray-300">
                                {
                                    currentDatetime && filteredLiveOutages && filteredLiveOutages.map((e, idx) => {


                                        return (
                                            <li className="py-1 px-2 flex justify-between md:py-2 md:px-4 items-start" key={idx}>
                                                <div className='flex gap-x-1'>
                                                    <div className='flex text-[12px] font-semibold  mr-1 justify-center items-center'>
                                                        <span className={`flex justify-center items-center rounded-full  h-8 w-8 ${e.interruptionType == "3" ? 'text-white bg-red-500' : 'bg-gray-700 text-white'} `}>{e.interruptionType == "3" ? "P" : "M"}</span>
                                                    </div>
                                                    <div className="flex flex-col gap-0">
                                                        <div className=" flex items-center gap-x-1 font-semibold text-[11px] text-orange-600 md:text-[11px] ">
                                                            <Link className='truncate text-ellipsis hover:text-sky-600 hover:underline' to={`/outages?fid=${e.feederId}`} target="_blank" rel="noopener noreferrer">{e.feederName}</Link>
                                                            <span className='text-[10px] text-gray-600'>({e.feederId})</span>  <span className='text-[10px] text-gray-600'> | {e.category}</span>
                                                        </div>
                                                        <span className='-mb-1 flex flex-col md:flex-row'>
                                                            <span className='-mt-1'>
                                                                <span className="font-medium text-[11px] text-gray-700 md:text-xs">Sub Division : </span>
                                                                <span className="font-semibold text-[11px] text-orange-600 md:text-xs">{e.subdivision},</span>
                                                            </span>
                                                            <span className='-mt-2 md:-mt-1'>
                                                                <span className="font-medium text-[11px] text-gray-700 md:ml-2 md:text-[11px]">SubStation : </span>
                                                                <span className="font-semibold text-[10px] text-orange-600 capitalize md:text-xs">{e.substation}</span>
                                                            </span>
                                                        </span>
                                                        <span className='-mt-1'>
                                                            <span className="font-medium text-[11px] text-gray-700 md:text-xs">Population : </span>
                                                            <span className=" font-semibold text-[11px] text-gray-500 md:text-xs">{e.population},</span>
                                                            <span className="font-medium text-[11px] text-gray-700 ml-2 md:text-xs">Villages : </span>
                                                            <span className=" font-semibold text-[11px] text-gray-500 md:text-xs">{e.villageCnt}</span>

                                                        </span>
                                                    </div>
                                                </div>

                                                <div className='flex flex-col justify-end text-right'>
                                                    <span className="font-medium text-[11px] text-gray-700  md:text-[12px]">{e.eventDateTime}</span>
                                                    <LiveDuration currentLocalDateTime={currentDatetime} timestamp={e.oTime} />

                                                    {/* <div className="flex gap-x-2 py-1 items-center justify-end">
                                                            <BoxArrowUpRight className='text-gray-400 text-xs md:text-sm' />
                                                        </div> */}
                                                </div>
                                            </li>
                                        )

                                    })
                                }

                            </ul>
                        </div>
                    </div>
                    {/* Modal */}
                    {showFilter && (
                        <div className="fixed inset-0 flex items-start justify-center z-10 backdrop-blur-xs cursor-pointer">
                            <div className="absolute inset-0 bg-black opacity-30 w-full"></div>
                            <div className=" bg-white m-2 z-10 md:w-1/3 w-full rounded-sm shadow-lg">
                                <div className="w-full">
                                    {/* Header */}
                                    <div className="flex bg-gray-100 px-5 py-2.5 flex-row justify-between items-center border-b border-gray-300 rounded-sm">
                                        <div className="w-full flex justify-between items-center ">
                                            <div className="flex items-center gap-1.5  font-semibold text-gray-700">
                                                <div className="text-sm"><FunnelFill /></div>
                                                <div className="text-[16px]">Filter</div>
                                            </div>
                                            <div className=""><XLg className="cursor-pointer hover:text-orange-500" onClick={() => setShowFilter(false)} /></div>
                                        </div>
                                    </div>
                                    {/* Header */}
                                    {/* Body */}
                                    <div className="px-5 py-3 flex flex-col">
                                        <p className='pb-2 pt-1 text-orange-600 font-medium'>Category</p>
                                        <ul className='pb-3'>
                                            {filterCategory.map((e) => (

                                                <li className=' flex  py-2 ' key={e.name}>
                                                    <input
                                                        type="checkbox"
                                                        value={e.name}
                                                        checked={e.isVisible}
                                                        onChange={handleCheckboxChange}
                                                        className='w-5 cursor-pointer'
                                                    />
                                                    <label className='ml-2 text-xs font-medium'>
                                                        {e.name}
                                                    </label>
                                                </li>

                                            ))}
                                        </ul>
                                        <p className='pb-2 pt-1 text-orange-600 font-medium'>Duration</p>
                                        <select className="w-full px-2 py-[6px] rounded-md  text-sm border border-gray-300 focus:border-gray-500 focus:outline-none" name="durationFilter" onChange={handleDurationChange} value={durationFilter} >
                                            <option value='0'>Select</option>
                                            <option value='120'>More than 2 Hours</option>
                                            <option value='240'>More than 4 Hours</option>
                                            <option value='360'>More than 6 Hours</option>
                                            <option value='480'>More than 8 Hours</option>
                                            <option value='600'>More than 10 Hours</option>
                                            <option value='720'>More than 12 Hours</option>
                                            <option value='1440'>More than 24 Hours</option>
                                        </select>
                                    </div>
                                    {/* Footer */}
                                    <div className="flex bg-gray-100 flex-row justify-center items-center border-b border-gray-300 rounded-sm">
                                        <div className="flex justify-end px-6 py-2 gap-2">
                                            <div className="bg-gray-700 px-3 py-1.5 text-[0.8125rem] font-semibold leading-5 text-white rounded-md text-center shadow-md hover:bg-gray-700 cursor-pointer hover:shadow-xl" onClick={() => setShowFilter(false)}>
                                                Cancel
                                            </div>
                                            <div className="bg-orange-600 px-3 py-1.5 text-[0.8125rem] font-semibold leading-5 text-white rounded-md text-center shadow-md hover:shadow-xl cursor-pointer" onClick={filterApply} >Submit</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Modal */}
                </div>
            </div >
        </>
    )

}

export default Summary;