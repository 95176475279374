import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import UserContext from '../UserContext'; // Assuming user state is managed here
import AddOutage from './AddOutage';

const ConditionalRoute = ({ children, path }) => {
    const { user } = useContext(UserContext);
    // console.log(user)
    // Redirect to home if user's company_name is not the required company
    if (path == '/outages/add') {
        if (!user || user.companyName !== 'GETCO' || user.orgType != 'Substation') {
            return <Navigate to="/" replace />;
        }
    }
    console.log(user)
    if (path == '/modules') {

        if (!user.permission.find((e) => e === 'manage-substation' || e === 'super admin' || e === 'admin')) {
            return <Navigate to="/" replace />;
        }
    }
    if (path == '/substation') {

        if (!user.permission.find((e) => e === 'manage-substation' || e === 'super admin' || e === 'admin')) {
            return <Navigate to="/" replace />;
        }
    }
    if (path == '/substation/create') {

        if (!user.permission.find((e) => e === 'manage-substation' || e === 'super admin' || e === 'admin')) {
            return <Navigate to="/" replace />;
        }
    }

    return children;
}
export default ConditionalRoute;