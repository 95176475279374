import { createSlice } from "@reduxjs/toolkit";
const appSlice = createSlice({
    name: 'app',
    initialState: {
        isLoggedIn: false
    },
    reducers: {
        login: (state) => {
            state.isLoggedIn = !state.isLoggedIn;
        }
    }
});
export const { login } = appSlice.actions;
export default appSlice.reducer;