import React, { useState } from 'react'

const Alert = (props) => {

    // const [showAlert, setShowAlert] = useState(true);


    return (
        <>
            {

                <div className="  fixed inset-0 flex items-center justify-center z-50 backdrop-blur-sm cursor-pointer" onClick={() => props.setVisiblePopup(false)}>
                    <div className="absolute inset-0 bg-black opacity-30 w-full"></div>
                    <div className="animate-fade-in-up bg-white rounded-lg p-5 z-10 flex-col flex items-center justify-center w-1/4 shadow-lg">
                        <div className="my-5 ">
                            <div className="flex flex-col items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="text-red-700 bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                                    <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                                <div className=" uppercase my-1 text-2xl text-red-700 font-semibold">Error</div>
                            </div>
                        </div>

                        <div className="my-2 text-md font-semibold text-red-700 Capitalize">
                            <p>{props.message}</p>
                        </div>


                        <button className="w-28 mt-6 bg-gray-800 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded-md">
                            Close
                        </button>
                    </div>
                </div >
            }
        </>

    )
}

export default Alert